import React, { FormEvent, useEffect, useRef, useState } from 'react'
import InputField from '../InputField/InputField'
import { storage, firestore } from '../../firebase'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './UploadForm.scss'
import { useAppContext } from '../../App'
import { useAuth } from '../../contexts/AuthContext'

const UploadForm = () => {
  const [isActive, setIsActive] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const [percent, setPercent] = useState(0)
  const [isUploading, setIsUploading] = useState(false)
  const [downloadURL, setDownloadURL] = useState('')
  const [uploadFile, setUploadFile] = useState<File | null>(null)
  const fileInput = useRef(null)
  const { title, setTitle, password } = useAppContext()!
  const [fileName, setFileName] = useState('')
  // @ts-ignore
  const { currentUser } = useAuth()

  const dropHandler = (event: React.DragEvent) => {
    event.preventDefault()
    // if (event.dataTransfer.items) {
    // Use DataTransferItemList interface to access the file(s)
    for (let i = 0; i < event.dataTransfer.items.length; i++) {
      // If dropped items aren't files, reject them
      if (event.dataTransfer.items[i].kind === 'file') {
        const file = event.dataTransfer.items[i].getAsFile()
        setUploadFile(file)
      }
    }
    // } else {
    //   // Use DataTransfer interface to access the file(s)
    //   for (let i = 0; i < event.dataTransfer.files.length; i++) {
    //     console.log(
    //       '... file[' + i + '].name = ' + event.dataTransfer.files[i].name
    //     )
    //   }
    // }
  }

  const changeHandler = (event: FormEvent<HTMLInputElement>) => {
    if (!fileInput) return
    // ToDo Fix TypeScript
    // @ts-ignore
    const file = fileInput.current.files[0]
    setUploadFile(file)
  }

  useEffect(() => {
    if (!uploadFile) return
    setTitle(uploadFile!.name)
    setFileName(uploadFile!.name)
    // console.log(currentUser)
  }, [uploadFile, setTitle])

  const clipboardHandler = () => {
    if (typeof navigator.clipboard === 'undefined') return
    navigator.clipboard.writeText(downloadURL)
    toast.success('Copied to clipboard')
    console.log(`Copied to clipboard: ${downloadURL}`)
  }

  // Ignore default browser drag behavior
  const dragOverHandler = (event: React.DragEvent) => {
    event.preventDefault()
  }

  const startUpload = async () => {
    setIsUploading(true)
    setIsActive(false)
    setIsDone(false)
    setPercent(0)
    if (uploadFile === null) return
    const file = uploadFile as File
    const filepath = `uploads/${currentUser.email}/${file.name}`

    if (!(await fileExistsInDatabase(filepath))) {
      console.error(`File already exists: ${filepath}`)
      toast.error('File already exists')
      setIsUploading(false)
      return
    }

    // Create a root reference
    const storageRef = storage().ref()

    // Create a reference to 'mountains.jpg'
    const uploadTask = storageRef.child(filepath).put(file)

    uploadTask.on(
      storage.TaskEvent.STATE_CHANGED,
      (snapshot: any) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        setPercent(progress)
      },
      (error) => {
        // @ts-ignore
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break

          case 'storage/canceled':
            // User canceled the upload
            break

          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break
        }
        setIsUploading(false)
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref
          .getDownloadURL()
          .then(async function (storageURL) {
            const ref = await addFileToDatabase(filepath, storageURL)
            if (!ref) {
              console.log('error')
              return
            }
            setIsDone(true)
            setIsUploading(false)
            setDownloadURL(`https://transfer.i40.de/get/${ref.id}`)
            console.log('File available at', storageURL)
          })
      }
    )
  }

  const fileExistsInDatabase = async (filepath: string) => {
    const existingRef = await firestore()
      .collection('links')
      .where('storageReference', '==', filepath)
      .get()

    return existingRef.docs.length !== 0 ? false : true
  }

  const addFileToDatabase = async (filepath: string, storageURL: string) => {
    const ref = await firestore()
      .collection('links')
      .add({
        storageReference: filepath,
        title,
        password,
        fileName,
        storageURL,
        uploader: {
          name: currentUser.displayName,
          email: currentUser.email,
        },
        created: firestore.FieldValue.serverTimestamp(),
      })
    return ref
  }

  return (
    <>
      <h1>
        <span className="u-t-primary"></span> i40 Transfer | Upload
      </h1>
      <div className="container">
        <div className="form">
          <div className="left">
            <div
              className={`dropzone ${isActive ? 'active' : ''} ${
                isDone ? 'done' : ''
              }`}
              style={{
                background: `linear-gradient(0deg, var(--primary) 0%, var(--primary) ${percent}%, var(--bg-secondary) ${percent}%, var(--bg-secondary) 100%)`,
              }}
              onDrop={dropHandler}
              onDragEnter={() => {
                setIsActive(true)
                setIsDone(false)
                setPercent(0)
              }}
              onDragLeave={() => setIsActive(false)}
              onDragOver={dragOverHandler}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M10.997 19.06c0 1.277-2.996 1.268-2.996.003 0-1.314 2.996-1.344 2.996-.003zm11.003-8.06v13h-20v-24h8.409c4.857 0 3.335 8 3.335 8 3.009-.745 8.256-.419 8.256 3zm-14-4h3v-1h-3v1zm0-2h3v-1h-3v1zm0-2h3v-1h-3v1zm0 6h3v-1h-3v1zm0 2h3v-1h-3v1zm0 2h3v-1h-3v1zm3.925 5.5l-.925-4.5h-3l-.925 4.5c-.393 1.578.801 2.5 2.425 2.5 1.626 0 2.817-.924 2.425-2.5zm3.984-12.723c2.047-.478 4.805.279 6.091 1.179-1.494-1.998-5.23-5.708-7.432-6.881 1.156 1.168 1.563 4.234 1.341 5.702z"
                />
              </svg>

              <input
                type="file"
                className="upload-file-input"
                id="customFile"
                ref={fileInput}
                onChange={changeHandler}
                hidden
              />
            </div>
            <label className="upload-file-label" htmlFor="customFile">
              <span className="button upload-file-text">Choose file …</span>
              {/* <button className='button upload-file-button'>Browse</button> */}
            </label>
          </div>
          <div className="right">
            <InputField name="Title" />
            <InputField name="Password" />
            <button
              onClick={startUpload}
              disabled={isUploading}
              className="button button--primary u-mt-auto"
            >
              Upload
            </button>
          </div>
        </div>

        {isDone && (
          <div className="info">
            <h3>File upload successful!</h3>
            <button
              onClick={clipboardHandler}
              className="button button--primary"
            >
              Copy link to clipboard
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default UploadForm
